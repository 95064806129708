import Vue from 'vue';
import VueRouter from 'vue-router';
import RoleFork from '@/components/RoleFork';
import routeNames from './routeNames';

const roles = {
  admin: 'admin',
  developer: 'developer',
  analyst: 'analyst',
};

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: routeNames.rfps,
    component: () => import('@/views/Home.vue'),
    meta: { authorize: [roles.admin, roles.developer, roles.analyst] },
    children: [
      {
        path: '/projects',
        component: () => import('@/views/ProjectListDev.vue'),
        meta: { authorize: [roles.admin, roles.developer] },
      },
      {
        path: '/rfps',
        component: () => import('@/views/RfpListDev.vue'),
        meta: { authorize: [roles.admin, roles.developer] },
      },
      {
        path: '/my-company',
        component: () => import('@/views/DeveloperInformation.vue'),
        meta: { authorize: [roles.admin, roles.developer] },
      },
    ],
  },
  {
    path: '/administration',
    name: routeNames.adminstration,
    component: () => import('@/views/Administration.vue'),
    meta: { authorize: [roles.admin] },
    redirect: '/administration/users',
    children: [
      {
        path: '/administration/companies',
        name: routeNames.companyList,
        component: () => import('@/views/CompanyList.vue'),
        meta: { authorize: [roles.admin] },
      },
      {
        path: '/administration/companies/add',
        name: routeNames.companyAdd,
        component: () => import('@/views/CompanyAdd.vue'),
        meta: { authorize: [roles.admin] },
      },
      {
        path: '/administration/companies/:id/edit',
        name: routeNames.companyEdit,
        component: () => import('@/views/CompanyEdit.vue'),
        meta: { authorize: [roles.admin] },
      },
      {
        path: '/administration/users',
        name: routeNames.userList,
        component: () => import('@/views/UserList.vue'),
        meta: { authorize: [roles.admin] },
      },
      {
        path: '/administration/users/add',
        name: routeNames.userAdd,
        component: () => import('@/views/UserAdd.vue'),
        meta: { authorize: [roles.admin] },
      },
      {
        path: '/administration/users/:id/edit',
        name: routeNames.userEdit,
        component: () => import('@/views/UserEdit.vue'),
        meta: { authorize: [roles.admin] },
      },
    ],
  },
  {
    path: '/settings',
    name: routeNames.settings,
    component: () => import('@/views/Settings.vue'),
    meta: { authorize: [roles.admin, roles.analyst, roles.developer] },
  },
  {
    path: '/health',
    name: 'Health',
    component: () => import('@/views/Health.vue'),
    meta: { authorize: [roles.admin] },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
  },
  {
    path: '/bilaterals/add',
    name: routeNames.bilateralAdd,
    component: () => import('@/views/BilateralAdd.vue'),
    meta: { authorize: [roles.admin, roles.analyst] },
  },
  {
    path: '/bilaterals/:id/edit',
    name: routeNames.bilateralEdit,
    component: () => import('@/views/BilateralEdit.vue'),
    meta: { authorize: [roles.admin, roles.analyst] },
  },
  {
    path: '/rfps/omni-projects',
    name: routeNames.omniProjects,
    component: () => import('@/views/OmniProjectList.vue'),
    meta: { authorize: [roles.admin] },
  },
  {
    path: '/rfps/omni-projects/:id',
    name: routeNames.omniProjectEdit,
    component: () => import('@/views/OmniProjectEdit.vue'),
    meta: { authorize: [roles.admin] },
  },
  {
    path: '/projects/add',
    name: routeNames.projectAdd,
    component: () => import('@/views/ProjectAddDev.vue'),
    meta: { authorize: [roles.developer] },
  },
  {
    path: '/projects/:id/edit',
    name: routeNames.projectEdit,
    component: () => import('@/views/ProjectEditDev.vue'),
    meta: { authorize: [roles.developer] },
  },
  {
    path: '/privacy-policy',
    name: routeNames.privacyPolicy,
    component: () => import('@/views/Privacy.vue'),
    meta: { authorize: [roles.admin, roles.analyst, roles.developer] },
  },
  {
    path: '/terms-of-use',
    name: routeNames.terms,
    component: () => import('@/views/Terms.vue'),
    meta: { authorize: [roles.admin, roles.analyst, roles.developer] },
  },
  {
    path: '/rfps/add',
    name: routeNames.rfpsAdd,
    component: () => import('@/views/RfpAdd.vue'),
    meta: { authorize: [roles.analyst] },
  },
  {
    path: '/rfps/:id/edit',
    name: routeNames.rfpsEdit,
    component: () => import('@/views/RfpEdit.vue'),
    meta: { authorize: [roles.analyst] },
  },
  {
    path: '/rfps/:id/proposals/add/:companyId',
    name: routeNames.proposalAdd,
    component: () => import('@/views/ProposalAdd/ProposalAdd.vue'),
    meta: { authorize: [roles.analyst, roles.developer] },
  },
  {
    path: '/rfps/:id/proposals/:proposalId',
    name: routeNames.proposalEdit,
    component: () => import('@/views/ProposalEdit.vue'),
    meta: { authorize: [roles.analyst, roles.developer] },
  },
  {
    path: '/rfps/:id',
    name: routeNames.rfpsView,
    component: RoleFork,
    meta: { authorize: [roles.analyst, roles.developer] },
    props: {
      DeveloperComponent: () => import('@/views/RfpViewDev.vue'),
      DefaultComponent: () => import('@/views/RfpView.vue'),
    },
  },
  {
    path: '*',
    component: () => import('@/views/NotFound.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, _, next) => {
  const nearestWithName = to.matched
    .slice()
    .reverse()
    .find(route => route.name);
  if (nearestWithName) {
    document.title = `${nearestWithName.name} - Artemis Advisor`;
  }
  next();
});

export default router;
