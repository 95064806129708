import { format, formatISO, isValid, parse, parseISO } from 'date-fns';

export const isFormattedDate = date =>
  /^(0*[1-9]|1[0-2])[/](0*[1-9]|[12]\d|3[01])[/]\d{4}$/.test(date); // mm/dd/yyyy

export const isIsoDate = date =>
  /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/.test(date); // yyyy-mm-dd

export const convertToDate = value => {
  switch (true) {
    case value instanceof Date:
      return value;
    case isFormattedDate(value):
      return parse(value, 'MM/dd/yyyy', new Date());
    case isIsoDate(value):
      return parseISO(value);
    case isValid(parseISO(value)):
      return parseISO(value);
    default:
      return '';
  }
};

export const formatDate = value => {
  const date = convertToDate(value);
  return value ? format(date, 'MM/dd/yyyy') : '';
};

export const formatDateIso = value => {
  const date = convertToDate(value);
  return formatISO(date, {
    representation: 'date',
  });
};

export const formatDateLong = value => {
  const date = convertToDate(value);
  if (date instanceof Date === false) return;
  return value ? format(date, 'MMMM d, yyyy') : '';
};
