import { isAfter, isBefore, isEqual } from 'date-fns';
import { extend } from 'vee-validate';
import {
  between,
  integer,
  min_value,
  min,
  required,
} from 'vee-validate/dist/rules';
import { convertToDate } from '@/utilities/date';
import usStatesList from './usStatesList';

const latLongRegex = /^\s*[-+]?\d{1,3}\.\d{1,10}\s*$/;

extend('required', {
  ...required,
  message: '{_field_} is required',
});
extend('at_least_one', {
  ...required,
  message: 'At least one {_field_} must be selected',
});
extend('integer', {
  ...integer,
  message: '{_field_} must be a whole number',
});
extend('min', {
  ...min,
  message: '{_field_} must be at least {length} characters',
});
extend('min_value', {
  ...min_value,
  message: '{_field_} must be {min} or greater',
});
extend('between', {
  ...between,
  message: '{_field_} must be between {min} and {max}',
});
extend('positive', {
  validate: value => value >= 0,
  message: '{_field_} must be positive',
});
extend('numeric', {
  validate: value => !isNaN(value.replace(',', '')),
  message: '{_field_} must be a number',
});
extend('lat_long', {
  validate: value => latLongRegex.test(value),
  message: '{_field_} must be a valid coordinate',
});
extend('lat_long_combo', {
  validate: value => {
    const [latitude, longitude, overflow] = value.split(',');
    if (!latitude || !longitude || overflow) return false;
    return (
      latLongRegex.test(latitude.trim()) && latLongRegex.test(longitude.trim())
    );
  },
  message: '{_field_} must be a valid coordinate pair separated by a comma',
});
extend('date', {
  validate: value => /^\d{1,2}\/\d{1,2}\/\d{4}$/.test(value),
  message: '{_field_} must be formatted mm/dd/yyyy',
});
extend('profile8760', {
  validate: value =>
    value.split(/\r?\n/).filter(value => String(value)).length === 8760,
  message: 'Must contain exactly 8760 hours of data',
});
extend('us_state', {
  validate: value => usStatesList.includes(value.trim().toUpperCase()),
  message: '{_field_} must be a two letter US state abbreviation',
});
extend('year', {
  validate: value => /^\d{4}$/.test(value),
  message: '{_field_} must be a four digit year',
});
extend('zip', {
  validate: value => /^\d{5}$/.test(value),
  message: '{_field_} must be a five digit US ZIP code',
});

// date rules
extend('date_before', {
  params: ['target'],
  validate: (value, { target }) => {
    const valueDate = convertToDate(value);
    const targetDate = convertToDate(target);
    return isBefore(valueDate, targetDate) || isEqual(valueDate, targetDate);
  },
  message: '{_field_} must be on or before {target}',
});
extend('date_after', {
  params: ['target'],
  validate: (value, { target }) => {
    const valueDate = convertToDate(value);
    const targetDate = convertToDate(target);
    return isAfter(valueDate, targetDate) || isEqual(valueDate, targetDate);
  },
  message: '{_field_} must be on or after {target}',
});
extend('is_unique', {
  params: ['target'],
  validate: (value, { target }) =>
    !target.valueOf().includes(value.valueOf().toLowerCase()),
  message: '{_field_} is already in use',
});
