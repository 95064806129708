import { make } from 'vuex-pathify';

const themeStorageKey = 'User-theme-preference';
const isSystemDarkMode = window.matchMedia('(prefers-color-scheme: dark)')
  .matches;

const state = {
  attributes: undefined, // Object
};

const getters = {
  ...make.getters(state),
  agreementSigned: state => state.attributes?.agreement_signed,
  companyUuid: state => state.attributes?.company_uuid,
  email: state => state.attributes?.email,
  emailEnabled: state => state.attributes?.email_enabled,
  isAdmin: state => state.attributes?.user_type === 'admin',
  isDarkMode: state => {
    const themePreference = state.attributes?.theme_preference;
    const savedPreference =
      window.localStorage.getItem(themeStorageKey) === 'DARK';
    switch (themePreference) {
      case undefined:
        return savedPreference;
      case null:
        return isSystemDarkMode;
      case 'DARK':
        return true;
      default:
        return false;
    }
  },
  themePreference: state => state.attributes?.theme_preference,
  isDeveloper: state => state.attributes?.user_type === 'developer',
  role: state => state.attributes?.user_type,
};

const mutations = {
  ...make.mutations(state),
  setAttributes(state, userAttributes) {
    saveThemePreference(userAttributes?.theme_preference);
    state.attributes = userAttributes;
  },
};

const actions = {
  ...make.actions(state),
  setAttributes({ commit }, userAttributes) {
    commit('setAttributes', userAttributes);
  },
};

function saveThemePreference(themePreference) {
  window.localStorage.setItem(themeStorageKey, themePreference);
}

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
};
