import { BootstrapVue } from 'bootstrap-vue';
import Vue from 'vue';
import App from './App.vue';
import './plugins';
import router from './router';
import store from './store';
import './styles/main.scss';
import 'leaflet/dist/leaflet.css';

Vue.config.productionTip = false;

Vue.use(BootstrapVue, {
  BToast: {
    solid: true,
    toaster: 'b-toaster-top-center',
    variant: 'success',
  },
});

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
