import axios from 'axios';
import { isPast } from 'date-fns';
import {
  extendSession,
  getAccessToken,
  getSessionEnd,
  login,
} from './AuthService';

const {
  VUE_APP_API_URL_LOCAL,
  VUE_APP_API_URL,
  VUE_APP_REQUIRE_LOGIN,
  VUE_APP_USE_LOCAL_API,
} = process.env;

const apiClient = axios.create({
  baseURL:
    VUE_APP_USE_LOCAL_API === 'true' ? VUE_APP_API_URL_LOCAL : VUE_APP_API_URL,
});

const isLoginRequired = VUE_APP_REQUIRE_LOGIN !== 'false';

apiClient.interceptors.request.use(
  async config => {
    if (isLoginRequired) {
      const sessionEnd = new Date(getSessionEnd());
      const isSessionEnded = isPast(sessionEnd);
      if (isSessionEnded) {
        try {
          await extendSession();
        } catch (error) {
          login();
        }
      }
      const token = getAccessToken();
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

const ApiService = {
  apiClient: apiClient,
  async calculateGetHistoricalEconomics(
    rfpUuid,
    { forecastType, forwardsYears, startDate, vintage }
  ) {
    const response = await apiClient.post(
      `calculate/rfp-economics/${rfpUuid}`,
      {
        forecast_type: forecastType,
        num_forwards_years: forwardsYears ? Number(forwardsYears) : undefined,
        start_date: startDate,
        vintage: vintage,
      }
    );
    return response.data;
  },
  async commentCreate({ text, rfpUuid }) {
    const response = await apiClient.post('comment/create', {
      text: text,
      rfp_uuid: rfpUuid,
    });
    return response.data;
  },
  async companyCreate({
    ciExperienceDescription,
    ciExperienceMw,
    developmentExperienceDescription,
    developmentExperienceMw,
    name,
    yearFounded,
  }) {
    const response = await apiClient.post('company/create', {
      ci_experience_description: ciExperienceDescription,
      ci_experience_mw: ciExperienceMw,
      development_experience_description: developmentExperienceDescription,
      development_experience_mw: developmentExperienceMw,
      name: name,
      year_founded: yearFounded,
    });
    return response.data;
  },
  async companyGet(uuid) {
    const response = await apiClient.get(`company/${uuid}`);
    return response.data;
  },
  async companyDelete(uuid) {
    const response = await apiClient.delete(`company/delete/${uuid}`);
    return response.data;
  },
  async companyGetAll() {
    const response = await apiClient.get(`/company/get-all`);
    return response.data;
  },
  async companyUuidGetProjects(uuid) {
    const response = await apiClient.get(`/company/projects/${uuid}`);
    return response.data;
  },
  async companyUpdate(
    uuid,
    {
      yearFounded,
      developmentExperienceMw,
      developmentExperienceDescription,
      ciExperienceMw,
      ciExperienceDescription,
    }
  ) {
    const response = await apiClient.put(`company/edit/${uuid}`, {
      year_founded: yearFounded,
      development_experience_mw: developmentExperienceMw,
      development_experience_description: developmentExperienceDescription,
      ci_experience_mw: ciExperienceMw,
      ci_experience_description: ciExperienceDescription,
    });
    return response.data;
  },
  async economicsGet(jobUuid) {
    const response = await apiClient.get(`economics-job/${jobUuid}`);
    return response.data;
  },
  async economicsGetByOffer({
    forecastType,
    forwardsYears,
    startDate,
    offerUuid,
    vintage,
  }) {
    const response = await apiClient.get('economics-job/get', {
      params: {
        forecast_type: forecastType,
        num_forwards_years: forwardsYears ? Number(forwardsYears) : undefined,
        offer_uuid: offerUuid,
        start_date: startDate,
        vintage: vintage,
      },
    });
    return response.data;
  },
  async forecastGetOptions() {
    const response = await apiClient.get(`/forecast/forecast-options`);
    return response.data;
  },
  async geocodeGetCoordinates(address) {
    const addressFormatted = encodeURIComponent(address);
    const response = await axios.get(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${addressFormatted}.json?access_token=pk.eyJ1Ijoid2luZGxvZ2ljcyIsImEiOiJjbDhvbmRia3AwNGgzM25taHQzaXc4d21kIn0.f9wUilMuyB75FaLeFT_bzg`
    );
    return response.data.features.map(feature => ({
      address: feature.place_name,
      id: feature.id,
      latitude: feature.geometry.coordinates[0],
      longitude: feature.geometry.coordinates[1],
    }));
  },
  async health() {
    try {
      let res = await apiClient.get('/health');
      let data = res.data;
      if (!data) {
        res.data = res.statusText;
      }
      return { data: res.data };
    } catch (error) {
      let message = error.message;
      if ('Message' in error.response.data) {
        message = error.response.data.Message;
      }
      return { error: message };
    }
  },
  async npvDistributionGet(jobUuid) {
    const response = await apiClient.get(`npv-distribution-job/${jobUuid}`);
    return response.data;
  },
  async rfpCreate({
    companies,
    correlationFactor,
    customerName,
    deadlines,
    discountRate,
    inflationRate,
    isBilateral = false,
    loads,
    name,
    rfpFileName,
    series8760,
  }) {
    let res = await apiClient.post('/rfp/create', {
      companies,
      correlation_factor: correlationFactor,
      customer_name: customerName,
      deadlines,
      discount_rate: Number(discountRate),
      inflation_rate: Number(inflationRate),
      is_bilateral: isBilateral,
      load_profile: series8760,
      loads,
      name,
      rfp_file_name: rfpFileName,
    });
    return res.data;
  },
  async rfpDelete(uuid) {
    const res = await apiClient.delete(`/rfp/delete/${uuid}`);
    return res.data;
  },
  async rfpUpdate(
    uuid,
    {
      correlationFactor,
      companies,
      customerName,
      deadlines,
      discountRate,
      inflationRate,
      loads,
      name,
      rfpFileName,
      series8760,
    },
    rfp
  ) {
    let res = await apiClient.put(`/rfp/edit/${uuid}`, {
      ...rfp,
      companies,
      correlation_factor: correlationFactor,
      customer_name: customerName,
      deadlines,
      discount_rate: discountRate,
      inflation_rate: inflationRate,
      load_profile: series8760,
      loads,
      name,
      rfp_file_name: rfpFileName,
    });
    return res.data;
  },
  async rfpUpdateArchiveBoolean(uuid, value) {
    const res = await apiClient.put(`/rfp/set-archived/${uuid}`, {
      archived: value,
    });
    return res.data;
  },
  async rfpGetAll() {
    const res = await apiClient.get('/rfp/get-all');
    return res.data;
  },
  async rfpGet(uuid) {
    const res = await apiClient.get(`/rfp/${uuid}`);
    return res.data;
  },
  async rfpGetWithFilters(options = {}) {
    const {
      archived = false,
      bilaterals = false,
      pageNumber = 1,
      pageSize = 25,
      sort,
    } = options;
    const res = await apiClient.get(`/rfp/get-with-filters`, {
      params: {
        archived,
        bilaterals,
        page_number: pageNumber,
        page_size: pageSize,
        sort_method: sort,
      },
    });
    return res.data;
  },
  async rfpGetComments(uuid) {
    const res = await apiClient.get(`/rfp/comments/${uuid}`);
    return res.data;
  },
  async rfpGetCompanies(uuid) {
    const res = await apiClient.get(`/rfp/companies/${uuid}`);
    return res.data;
  },
  async rfpGetDeadline(uuid) {
    let res = await apiClient.get(`/rfp/deadlines/${uuid}`);
    return res.data;
  },
  async rfpGetProposals(uuid) {
    let res = await apiClient.get(`/rfp/proposals/${uuid}`);
    return res.data;
  },
  async rfpGetRfpFile(uuid) {
    const res = await apiClient.get(`/rfp/download-rfp-file/${uuid}`);
    return res.data;
  },
  async rfpShortlist(uuid, offerUuids) {
    const res = await apiClient.post(`/rfp/shortlist/${uuid}`, {
      shortlisted_offer_uuids: offerUuids,
    });
    return res.data;
  },
  async notificationGetAll() {
    const res = await apiClient.get('/notification/get-all');
    return res.data;
  },
  async notificationView({ rfpUuid, email }) {
    const res = await apiClient.post(`/notification/view`, {
      rfp_uuid: rfpUuid,
      user_email: email,
    });
    return res.data;
  },
  async offerCreate(offer) {
    const res = await apiClient.post(`/offer/create`, offer);
    return res.data;
  },
  async offerDelete(uuid) {
    const res = await apiClient.delete(`/offer/delete/${uuid}`);
    return res.data;
  },
  async offerGet(uuid) {
    const res = await apiClient.get(`/offer/${uuid}`);
    return res.data;
  },
  async offerGetAll(options = {}) {
    const { fromOmniScenario = null } = options;
    const res = await apiClient.get(`/offer/get-all`, {
      params: {
        from_omni_scenario: fromOmniScenario,
      },
    });
    return res.data;
  },
  async offerGetResultsMonthly(
    uuid,
    { forecastType, forwardsYears, startDate, vintage }
  ) {
    const res = await apiClient.get(`/offer/results-monthly`, {
      params: {
        forecast_type: forecastType,
        offer_uuid: uuid,
        vintage: vintage,
        start_date: startDate,
        num_forwards_years: forwardsYears ? Number(forwardsYears) : undefined,
      },
    });
    return res.data;
  },
  async offerGetCalculations({
    aggregation,
    forecastType,
    forwardsYears,
    startDate,
    uuid,
    vintage,
  }) {
    const res = await apiClient.get(`/offer/download-calculations`, {
      params: {
        aggregation: aggregation,
        forecast_type: forecastType,
        num_forwards_years: forwardsYears,
        offer_uuid: uuid,
        start_date: startDate,
        vintage: vintage,
      },
    });
    return res.data;
  },
  async offerGetProbabilisticAnalysis({
    aggregation,
    forecastType,
    forwardsYears,
    startDate,
    uuid,
    vintage,
  }) {
    const res = await apiClient.get(
      `/offer/download-probabilistic-analysis-table`,
      {
        params: {
          aggregation: aggregation,
          forecast_type: forecastType,
          num_forwards_years: forwardsYears,
          offer_uuid: uuid,
          start_date: startDate,
          vintage: vintage,
        },
      }
    );
    return res.data;
  },
  async omniScenarioCreate(scenario) {
    let res = await apiClient.post('/omni-scenario/create', {
      county: scenario.county,
      expected_cod: scenario.anticipatedCod,
      generation_8760: scenario.series8760,
      iso_ba: scenario.market,
      latitude: scenario.latitude,
      longitude: scenario.longitude,
      ncf: scenario.ncf,
      omni_project_id: scenario.projectId,
      omni_project_name: scenario.projectName,
      omni_proposal_id: scenario.proposalId,
      omni_proposal_name: scenario.proposalName,
      omni_scenario_id: scenario.scenarioId,
      omni_scenario_name: scenario.scenarioName,
      ppa_price: scenario.ppaPrice,
      ppa_term: scenario.ppaTerm,
      primary_interconnection_queue: scenario.primaryInterconnectionQueue,
      projectUuid: scenario.projectUuid,
      size_mw: scenario.sizeMw,
      state: scenario.state,
      technology: scenario.technology,
    });
    return res.data;
  },
  async omniScenarioGetAll() {
    const res = await apiClient.get(`/omni-scenario/get-all`);
    return res.data;
  },
  async omniScenarioGetScenarioId(scenarioId) {
    let res = await apiClient.post('/omni/search-omni-scenario', {
      omni_scenario_id: scenarioId,
    });
    return res.data;
  },
  async omniScenarioGetUuid(uuid) {
    const res = await apiClient.get(`/omni-scenario/${uuid}`);
    return res.data;
  },
  async omniScenarioRefreshAll() {
    let res = await apiClient.post('/omni-scenario/refresh-all');
    return res.data;
  },
  async omniScenarioUpdate(uuid, scenario) {
    const res = await apiClient.put(`/omni-scenario/edit/${uuid}`, {
      allow_omni_updates: scenario.allowOmniUpdates,
      county: scenario.county,
      expected_cod: scenario.anticipatedCod,
      generation_8760: scenario.series8760,
      iso_ba: scenario.market,
      latitude: scenario.latitude,
      longitude: scenario.longitude,
      ncf: scenario.ncf,
      omni_project_name: scenario.projectName,
      omni_proposal_name: scenario.proposalName,
      omni_scenario_name: scenario.scenarioName,
      ppa_price: scenario.ppaPrice,
      ppa_term: scenario.ppaTerm,
      primary_interconnection_queue: scenario.primaryInterconnectionQueue,
      projectUuid: scenario.projectUuid,
      size_mw: scenario.sizeMw,
      state: scenario.state,
      technology: scenario.technology,
    });
    return res.data;
  },
  async projectCreate(project) {
    let res = await apiClient.post('/project/create', {
      name: project.name,
      company_uuid: project.companyUuid,
      anticipated_cod: project.anticipatedCod,
      queue_number: project.queueNumber,
      longitude: project.longitude,
      projected_p90_capacity_factor: project.projectedP90CapacityFactor,
      state: project.state,
      county: project.county,
      projected_p50_capacity_factor: project.projectedP50CapacityFactor,
      project_type: project.projectType,
      project_total_nameplate_capacity: project.projectTotalNameplateCapacity,
      series_8760: project.series8760,
      latitude: project.latitude,
      interconnection_point: project.interconnectionPoint,
      guaranteed_cod: project.guaranteedCod,
      market: project.market,
    });
    return res.data;
  },
  async projectEdit(uuid, project) {
    let res = await apiClient.put(`/project/edit/${uuid}`, {
      name: project.name,
      company_uuid: project.companyUuid,
      anticipated_cod: project.anticipatedCod,
      queue_number: project.queueNumber,
      longitude: project.longitude,
      projected_p90_capacity_factor: project.projectedP90CapacityFactor,
      state: project.state,
      county: project.county,
      projected_p50_capacity_factor: project.projectedP50CapacityFactor,
      project_type: project.projectType,
      project_total_nameplate_capacity: project.projectTotalNameplateCapacity,
      series_8760: project.series8760,
      latitude: project.latitude,
      interconnection_point: project.interconnectionPoint,
      guaranteed_cod: project.guaranteedCod,
      market: project.market,
    });
    return res.data;
  },
  async projectGet(uuid) {
    const res = await apiClient.get(`/project/${uuid}`);
    return res.data;
  },
  async projectGetAll() {
    const res = await apiClient.get('/project/get-all');
    return res.data;
  },
  async projectGetAllProposalStatuses() {
    const res = await apiClient.get('/proposal/current-user-proposal-statuses');
    return res.data;
  },
  async projectGetArchived(options = {}) {
    const { pageNumber, pageSize, sort } = options;
    const res = await apiClient.get('/project/archived', {
      params: {
        page_number: pageNumber,
        page_size: pageSize,
        sort_method: sort,
      },
    });
    return res.data;
  },
  async projectGetNonArchived(options = {}) {
    const { pageNumber, pageSize, sort } = options;
    const res = await apiClient.get('/project/non-archived', {
      params: {
        page_number: pageNumber,
        page_size: pageSize,
        sort_method: sort,
      },
    });
    return res.data;
  },
  async projectUpdateArchiveBoolean(uuid, value) {
    const res = await apiClient.put(`/project/set-archived/${uuid}`, {
      archived: value,
    });
    return res.data;
  },
  async proposalCreate({ companyUuid, rfpUuid, offers }) {
    let res = await apiClient.post('/proposal/create', {
      company_uuid: companyUuid,
      rfp_uuid: rfpUuid,
      offers,
    });
    return res.data;
  },
  async proposalEdit(uuid, { companyUuid, rfpUuid, offers }) {
    let res = await apiClient.put(`/proposal/edit/${uuid}`, {
      company_uuid: companyUuid,
      rfp_uuid: rfpUuid,
      offers,
    });
    return res.data;
  },
  async proposalGet(uuid) {
    let res = await apiClient.get(`/proposal/${uuid}`);
    return res.data;
  },
  async proposalGetAll() {
    let res = await apiClient.get(`/proposal/get-all`);
    return res.data;
  },
  async proposalGetOffers(uuid) {
    const res = await apiClient.get(`/proposal/offers/${uuid}`);
    return res.data;
  },
  async proposalSubmit(uuid) {
    let res = await apiClient.post(`proposal/submit/${uuid}`);
    return res.data;
  },
  async respondentGet(uuid) {
    let res = await apiClient.get(`/respondent/${uuid}`);
    return res.data;
  },
  async userCreate({ email, companyUuid, userType }) {
    let res = await apiClient.post('/user/create', {
      email: email,
      company_uuid: companyUuid,
      user_type: userType,
    });
    return res.data;
  },
  async userDelete(email) {
    let res = await apiClient.delete(`/user/delete/${email}`);
    return res.data;
  },
  async userEdit(email, { companyUuid, userType }) {
    let res = await apiClient.put(`/user/edit/${email}`, {
      company_uuid: companyUuid,
      user_type: userType,
    });
    return res.data;
  },
  async userGet(email) {
    let res = await apiClient.get(`/user/${email}`);
    return res.data;
  },
  async userGetCurrent() {
    let res = await apiClient.get(`/user/current`);
    return res.data;
  },
  async userGetAll() {
    let res = await apiClient.get('/user/get-all');
    return res.data;
  },
  async userEditEmailPreference(emailEnabled) {
    const res = await apiClient.put(`/user/set-email-enabled/current`, {
      email_enabled: emailEnabled,
    });
    return res.data;
  },
  async userEditThemePreference(themeName) {
    const res = await apiClient.put(`/user/set-theme-preference/current`, {
      theme_preference: themeName,
    });
    return res.data;
  },
  async velocitySuiteGetProjectQueueInfo({ iso, queueNumber }) {
    let res = await apiClient.get('/velocity-suite/project-queue-info', {
      params: {
        iso: iso,
        queue_number: queueNumber,
      },
    });
    return res.data;
  },
  async velocitySuiteGetProjectNotes({ plantId }) {
    let res = await apiClient.get('/velocity-suite/project-notes', {
      params: {
        plant_id: plantId,
      },
    });
    return res.data;
  },
};

export default ApiService;
