export function formatFloat(val, max = null, min = null) {
  if (val == null) return undefined;
  if (typeof val === 'string') {
    // remove all non numeric characters
    val = val.replace(/[^0-9.-]+/g, '');
  }
  // max can pass -1 to indicate unlimited precision up to 20
  // maximumFractionDigits are capped at 20
  max = max < 0 || max > 20 ? 20 : max;
  return parseFloat(val).toLocaleString(undefined, {
    maximumFractionDigits: max !== null ? max : 2,
    minimumFractionDigits: min !== null ? min : 0,
  });
}

// scale dollars into abbreviated values
// e.g. 15000000 into "$15 M"
export function formatDollars(dollars, options = {}) {
  const { hideDecimals, showFull } = options;
  const suffixes = ['', 'k', 'M', 'B', 'T'];
  const isSmallValue = Math.abs(dollars) < 1000;
  if (showFull || isSmallValue) {
    return (dollars || 0).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: hideDecimals ? 0 : 2,
    });
  }
  let scaledDollars = dollars;
  let suffixKey = 0;
  while (Math.abs(scaledDollars) >= 1000 && suffixKey < suffixes.length - 1) {
    scaledDollars /= 1000;
    suffixKey += 1;
  }
  const isUnder100 = Math.abs(scaledDollars) > 100;
  const precision = isUnder100 ? 0 : 1;
  return `$${formatFloat(scaledDollars, hideDecimals ? 0 : precision, 0)} ${
    suffixes[suffixKey]
  }`;
}

export function formatMegawatts(megawatts) {
  // scale dollars into abbreviated values
  // e.g. 15000000 into "15 GW"
  const suffixes = ['MW', 'GW', 'TW', 'PW'];
  let scaledWatts = megawatts;
  let suffixKey = 0;
  while (Math.abs(scaledWatts) >= 1000 && suffixKey < suffixes.length - 1) {
    scaledWatts /= 1000;
    suffixKey += 1;
  }
  const precision = Math.abs(scaledWatts) > 100 ? 0 : 1;
  return `${formatFloat(scaledWatts, precision, 0)} ${suffixes[suffixKey]}`;
}

export function formatTons(tons) {
  // scale weight in tons
  // e.g. 15000000 into "15 M tons"
  const suffixes = ['tons', 'K tons', 'M tons', 'B tons', 'T tons'];
  let scaledTons = tons;
  let suffixKey = 0;
  while (Math.abs(scaledTons) >= 1000 && suffixKey < suffixes.length - 1) {
    scaledTons /= 1000;
    suffixKey += 1;
  }
  return `${formatFloat(scaledTons, 1, 0)} ${suffixes[suffixKey]}`;
}
