export default {
  adminstration: 'Administration',
  bilateralAdd: 'BilateralAdd',
  bilateralEdit: 'bilateralEdit',
  companyList: 'Companies',
  companyAdd: 'Add Company',
  companyEdit: 'Edit Company',
  companyView: 'Company Detail',
  omniProjects: 'OMNI Projects',
  omniProjectEdit: 'OMNI Project Edit',
  projectAdd: 'Add Project',
  projectEdit: 'Edit Project',
  proposalAdd: 'Submit Proposal',
  proposalEdit: 'Edit Proposal',
  privacyPolicy: 'Privacy Policy',
  rfps: 'RFPs',
  rfpsAdd: 'Add RFP',
  rfpsEdit: 'Edit RFP',
  rfpsView: 'RFP Summary',
  settings: 'Settings',
  terms: 'Terms of Use',
  userAdd: 'Add User',
  userEdit: 'Edit User',
  userList: 'Users',
  userView: 'User Details',
};
