<template>
  <component :is="DeveloperComponent" v-if="isDeveloper" />
  <component :is="DefaultComponent" v-else />
</template>

<script>
import { get } from 'vuex-pathify';

export default {
  name: 'RoleFork',
  props: {
    DeveloperComponent: {},
    DefaultComponent: {},
  },
  data() {
    return {};
  },
  computed: {
    ...get('user', ['isDeveloper']),
  },
};
</script>

<style lang="scss" scoped></style>
