import { make } from 'vuex-pathify';
import { offerTableFields } from '@/constants/tables';
import { formatDate } from '@/utilities/date';
import { formatFloat, formatTons } from '@/utilities/format';

const productMap = {
  CAPACITY: 'Capacity',
  ENERGY: 'Energy',
  REC: 'Rec',
};
const techMap = {
  SOLAR: 'Solar',
  WIND: 'Wind',
};

function formatEconomicsValue(value, status) {
  return status === 'SUCCESS' ? value : '-';
}

const state = {
  activeOfferIds: [],
  blend: {
    base: {
      percentage: '33.333',
      vintage: 'Fall_2022',
    },
    ihs: {
      percentage: '33.333',
      vintage: 'December_2022',
    },
    low_gas: {
      percentage: '33.333',
      vintage: 'Fall_2022',
    },
  },
  caseVintageMap: {},
  forecastOptions: [
    { text: 'Blend (33/33/33)', value: 'blend' },
    { text: 'IHS', value: 'ihs' },
    { text: 'Ventyx Base Case', value: 'base' },
    { text: 'Ventyx Low Gas', value: 'low_gas' },
    { text: 'Wood Mackenzie', value: 'woodmac' },
  ],
  forecastSelection: 'base',
  forwardsPricingOptions: [
    { text: '0 years', value: '0' },
    { text: '1 year', value: '1' },
    { text: '2 years', value: '2' },
    { text: '3 years', value: '3' },
    { text: '4 years', value: '4' },
    { text: '5 years', value: '5' },
    { text: '6 years', value: '6' },
    { text: '7 years', value: '7' },
    { text: '8 years', value: '8' },
    { text: '9 years', value: '9' },
    { text: '10 years', value: '10' },
  ],
  forwardsPricingSelection: '0',
  loadingForecasts: false,
  offers: [],
  projectTypeOptions: [
    { text: 'Solar', value: 'SOLAR' },
    { text: 'Wind', value: 'WIND' },
    { text: 'All', value: 'ALL' },
  ],
  projectTypeSelection: 'ALL',
  rfp: undefined, // Object
  rfpStatusOptions: [
    { text: 'Shortlisted', value: 'SHORTLISTED' },
    { text: 'All', value: 'ALL' },
  ],
  rfpStatusSelection: 'ALL',
  sensitivity: '0',
  showOfferModal: false,
  startDate: '01/01/2018',
  viewOptions: [
    { text: 'Forecast', value: 'forecast' },
    { text: 'Historical', value: 'historical' },
  ],
  viewSelection: 'forecast',
  vintageOptions: [{ text: 'Fall 2022', value: 'Fall_2022' }],
  vintageSelection: 'Fall_2022',
};

const getters = {
  ...make.getters(state),
  economicsRequestBodies: ({
    blend,
    forecastSelection,
    forwardsPricingSelection,
    startDate,
    vintageSelection,
    viewSelection,
  }) => {
    if (viewSelection === 'historical') {
      return [
        {
          forecastType: 'zema',
          startDate: formatDate(startDate),
          vintage: 'N/A',
        },
      ];
    }
    if (forecastSelection === 'blend') {
      return [
        {
          forecastType: 'base',
          forwardsYears: forwardsPricingSelection,
          vintage: blend.base.vintage,
        },
        {
          forecastType: 'ihs',
          forwardsYears: forwardsPricingSelection,
          vintage: blend.ihs.vintage,
        },
        {
          forecastType: 'low_gas',
          forwardsYears: forwardsPricingSelection,
          vintage: blend.low_gas.vintage,
        },
      ];
    }
    return [
      {
        forecastType: forecastSelection,
        forwardsYears: forwardsPricingSelection,
        vintage: vintageSelection,
      },
    ];
  },
  forecastSelectionName: ({ forecastOptions, forecastSelection }) => {
    const forecast = forecastOptions.find(
      ({ value }) => value === forecastSelection
    );
    return forecast?.text;
  },
  offersFiltered: ({ offers, projectTypeSelection, rfpStatusSelection }) => {
    return offers.filter(({ project_type, status }) => {
      const isAllProjectType = projectTypeSelection === 'ALL';
      const isMatchingProjectType =
        isAllProjectType || project_type === projectTypeSelection;
      const isAllRfpStatus = rfpStatusSelection === 'ALL';
      const isMatchingRfpStatus =
        isAllRfpStatus || rfpStatusSelection === status;
      return isMatchingProjectType && isMatchingRfpStatus;
    });
  },
  offersTableEncoded: (_state, { offersTableFormatted }) => {
    const headingRow = offerTableFields.map(item => item.label).join(',');
    const tableRows = offersTableFormatted.map(row =>
      offerTableFields
        .map(({ formatter = value => value, key }) => {
          return `"${formatter(row[key], key, row)}"`;
        })
        .join(',')
    );
    const tableString = `${headingRow}\r${tableRows.join('\r')}`;
    const csvContent = `data:text/csv;charset=utf-8, ${tableString}`;
    return encodeURI(csvContent);
  },
  offersTableFormatted: (_state, { offersFiltered, sensitivityAsPercent }) => {
    return offersFiltered.map(offer => ({
      company_name: offer.name,
      project_name: offer.project_name,
      rfp_name: offer.rfp_name,
      market: offer.market,
      project_type: techMap[offer.project_type],
      anticipated_cod: offer.anticipated_cod,
      product: offer.product.map(product => productMap[product]).join(' + '),
      proposed_bid_size: offer.proposed_bid_size,
      project_total_nameplate_capacity: offer.project_total_nameplate_capacity,
      projected_p50_capacity_factor: offer.projected_p50_capacity_factor,
      series_8760: formatFloat(
        offer.series_8760?.reduce(
          (accumulator, value) => accumulator + value,
          0
        ),
        0
      ),
      has_8760: offer.series_8760?.length === 8760,
      settlement_point: offer.settlement_point,
      settlement_market: offer.settlement_market,
      status: offer.status,
      term: offer.term,
      ppa_price: offer.ppa_price,
      // Economics Values:
      co2_avoided: offer.co2_avoided ? formatTons(offer.co2_avoided) : '-',
      savings: formatEconomicsValue(
        offer.revenue * sensitivityAsPercent - offer.cost,
        offer.economics_status
      ),
      mkt_energy_price: formatEconomicsValue(
        offer.mkt_energy_price * sensitivityAsPercent,
        offer.economics_status
      ),
      shape: formatEconomicsValue(
        offer.shape * sensitivityAsPercent,
        offer.economics_status
      ),
      gen_weighted_mkt_energy_price: formatEconomicsValue(
        offer.gen_weighted_mkt_energy_price * sensitivityAsPercent,
        offer.economics_status
      ),
      npv_per_mwh: formatEconomicsValue(
        (offer.revenue_npv * sensitivityAsPercent - offer.cost_npv) /
          (offer.project_npv / offer.npv_per_mwh),
        offer.economics_status
      ),
      npv: formatEconomicsValue(
        offer.revenue_npv * sensitivityAsPercent - offer.cost_npv,
        offer.economics_status
      ),
      // Supporting Values:
      economics_status: offer.economics_status,
      failure_reason: offer.failure_reason,
      latitude: offer.latitude,
      longitude: offer.longitude,
      uuid: offer.uuid,
    }));
  },
  sensitivityAsNumber: ({ sensitivity }, { sensitivityIsValid }) => {
    return sensitivityIsValid ? Number(sensitivity) : 0;
  },
  sensitivityAsPercent: (_state, { sensitivityAsNumber }) => {
    const sensitivityNumber = sensitivityAsNumber;
    return 1 + sensitivityNumber / 100;
  },
  sensitivityIsPositive: ({ sensitivity }) => {
    return sensitivity.startsWith('-') ? false : true;
  },
  sensitivityIsValid: ({ sensitivity }) => {
    const sensitivityNumber = Number(sensitivity);
    return !isNaN(sensitivityNumber) && sensitivity !== null;
  },
};

const mutations = {
  ...make.mutations(state),
  setVintageOptions(state) {
    const { caseVintageMap, forecastSelection } = state;
    const vintages = caseVintageMap[forecastSelection] || [];
    const hasVintages = vintages.length !== 0;
    state.vintageOptions = vintages.map(vintage => ({
      text: vintage.replace('_', ' '),
      value: vintage,
    }));
    state.vintageSelection = hasVintages
      ? state.vintageOptions.at(-1).value
      : '';
  },
  togglePositivity(state, sensitivityAsNumber) {
    const isZero = state.sensitivity === '0';
    state.sensitivity = isZero ? '-0' : String(sensitivityAsNumber * -1);
  },
};

const actions = {
  ...make.actions(state),
  setVintageOptions(context) {
    context.commit('setVintageOptions');
  },
  togglePositivity({ commit, getters }) {
    commit('togglePositivity', getters.sensitivityAsNumber);
  },
};

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
};
