<template>
  <div v-if="showContent === true">
    <slot></slot>
  </div>
  <div v-else-if="showContent === false" class="text-center mt-4">
    You are not authorized to view this page.
  </div>
</template>

<script>
import { get } from 'vuex-pathify';

export default {
  name: 'AuthWrapper',
  data() {
    return {
      route: undefined,
    };
  },
  computed: {
    ...get('user', ['email', 'isAdmin', 'role']),
    showContent() {
      const authorize = this.route?.meta?.authorize;
      if (!this.email) {
        return undefined;
      }
      if (!authorize || this.isAdmin) {
        return true;
      }
      return authorize.includes(this.role);
    },
  },
  watch: {
    async $route() {
      this.route = this.$route;
    },
  },
};
</script>

<style lang="scss" scoped></style>
