import axios from 'axios';
import { add } from 'date-fns';

const {
  VUE_APP_BASE_URL,
  VUE_APP_COGNITO_CLIENT_ID,
  VUE_APP_COGNITO_CLIENT_SECRET,
  VUE_APP_COGNITO_DOMAIN,
} = process.env;

const accessTokenKey = 'AuthService-access-token';
const refreshTokenKey = 'AuthService-refresh-token';
const savedPathKey = 'AuthService-saved-path';
const sessionEndKey = 'AuthService-session-end';

export async function extendSession() {
  const params = new URLSearchParams();
  params.append('client_id', VUE_APP_COGNITO_CLIENT_ID);
  params.append('grant_type', 'refresh_token');
  params.append('refresh_token', getRefreshToken());
  const response = await axios.post(
    `${VUE_APP_COGNITO_DOMAIN}/oauth2/token`,
    params,
    {
      headers: {
        Authorization: getAuthorizationCode(),
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }
  );
  if (response.data) setLocalValues(response.data);
  return response.data;
}

export async function getTokens(code) {
  const params = new URLSearchParams();
  params.append('code', code);
  params.append('client_id', VUE_APP_COGNITO_CLIENT_ID);
  params.append('grant_type', 'authorization_code');
  params.append('redirect_uri', `${VUE_APP_BASE_URL}/login`);
  const response = await axios.post(
    `${VUE_APP_COGNITO_DOMAIN}/oauth2/token`,
    params,
    {
      headers: {
        Authorization: getAuthorizationCode(),
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }
  );
  if (response.data) setLocalValues(response.data);
  return response.data;
}

export async function login() {
  window.localStorage.setItem(savedPathKey, window.location.pathname);
  window.location = `${VUE_APP_COGNITO_DOMAIN}/login?client_id=${VUE_APP_COGNITO_CLIENT_ID}&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=${VUE_APP_BASE_URL}/login`;
}

export async function logout() {
  window.localStorage.removeItem(accessTokenKey);
  window.localStorage.removeItem(refreshTokenKey);
  window.localStorage.removeItem(sessionEndKey);
  window.localStorage.setItem(savedPathKey, window.location.pathname);
  window.location = `${VUE_APP_COGNITO_DOMAIN}/logout?client_id=${VUE_APP_COGNITO_CLIENT_ID}&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=${VUE_APP_BASE_URL}/login`;
}

export function getAccessToken() {
  return window.localStorage.getItem(accessTokenKey);
}

export function getAuthorizationCode() {
  const authString = `${VUE_APP_COGNITO_CLIENT_ID}:${VUE_APP_COGNITO_CLIENT_SECRET}`;
  const authBase64 = btoa(authString);
  return `Basic ${authBase64}`;
}

export function getSavedPath() {
  const savedPath = window.localStorage.getItem(savedPathKey);
  window.localStorage.removeItem(savedPathKey);
  return savedPath;
}

export function getRefreshToken() {
  return window.localStorage.getItem(refreshTokenKey);
}

export function getSessionEnd() {
  return window.localStorage.getItem(sessionEndKey);
}

export async function getUserInfo(token) {
  const response = await axios.get(
    `${VUE_APP_COGNITO_DOMAIN}/oauth2/userInfo`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
}

export async function setLocalValues({
  access_token,
  refresh_token,
  expires_in,
}) {
  if (access_token) {
    window.localStorage.setItem(accessTokenKey, access_token);
  }
  if (refresh_token) {
    window.localStorage.setItem(refreshTokenKey, refresh_token);
  }
  if (expires_in) {
    const date = add(new Date(), {
      seconds: expires_in,
    });
    window.localStorage.setItem(sessionEndKey, date.toString());
  }
}
