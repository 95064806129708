import { formatDate } from '@/utilities/date';
import {
  formatDollars,
  formatFloat,
  formatMegawatts,
} from '@/utilities/format';

function formatCurrencyValue(value) {
  return Math.abs(value) >= 0 ? formatDollars(value) : '';
}

export const offerTableFields = [
  {
    key: 'company_name',
    label: 'Developer',
    sortable: true,
    stickyColumn: true,
    thClass: 'position-sticky',
  },
  {
    key: 'rfp_name',
    label: 'RFP',
    sortable: true,
  },
  {
    key: 'project_name',
    label: 'Project',
    sortable: true,
  },
  {
    key: 'status',
    label: 'Shortlist',
    sortable: true,
  },
  {
    key: 'settlement_point',
    label: 'ISO/Settlement Location',
    sortable: true,
  },
  {
    key: 'project_type',
    label: 'Tech',
    sortable: true,
  },
  {
    class: 'text-right',
    formatter: value => formatDate(value),
    key: 'anticipated_cod',
    label: 'COD Date',
    sortable: true,
  },
  {
    key: 'product',
    label: 'Product',
    sortable: true,
  },
  {
    class: 'text-right',
    key: 'proposed_bid_size',
    label: 'Bid Size',
    formatter: formatMegawatts,
    sortable: true,
    tdClass: 'text-nowrap',
  },
  {
    class: 'text-right',
    formatter: formatMegawatts,
    key: 'project_total_nameplate_capacity',
    label: 'Nameplate Size',
    sortable: true,
    tdClass: 'text-nowrap',
  },
  {
    class: 'text-right',
    key: 'annual_generation',
    label: 'Annual Gen.',
    formatter: (_value, _key, item) => {
      const capacityFactor = item.projected_p50_capacity_factor / 100 || 1;
      return `${formatFloat(
        capacityFactor * item.proposed_bid_size * 8760,
        0,
        0
      )} MWh`;
    },
    sortable: true,
    tdClass: 'text-nowrap',
  },
  {
    class: 'text-right',
    formatter: value => `${value} yrs`,
    key: 'term',
    label: 'Term',
    sortable: true,
  },
  {
    class: 'text-right',
    key: 'co2_avoided',
    label: 'Avoided CO₂',
    sortable: true,
    tdClass: 'text-nowrap',
  },
  {
    class: 'text-right',
    formatter: formatCurrencyValue,
    key: 'ppa_price',
    label: 'PPA Price',
    sortable: true,
  },
  {
    class: 'text-right',
    formatter: formatCurrencyValue,
    key: 'mkt_energy_price',
    label: 'Mkt ($/MWh)',
    sortable: true,
  },
  {
    class: 'text-right',
    formatter: formatCurrencyValue,
    key: 'shape',
    label: 'Shape ($/MWh)',
    sortable: true,
  },
  {
    class: 'text-right',
    formatter: formatCurrencyValue,
    key: 'gen_weighted_mkt_energy_price',
    label: 'Gen Wtd Mkt ($/MWh)',
    sortable: true,
  },
  {
    class: 'text-right',
    formatter: formatCurrencyValue,
    key: 'savings',
    label: 'Savings',
    sortable: true,
    tdClass: 'text-nowrap',
  },
  {
    class: 'text-right',
    formatter: formatCurrencyValue,
    key: 'npv_per_mwh',
    label: 'NPV/MWh',
    sortable: true,
  },
  {
    class: 'project-npv-cell text-right',
    formatter: formatCurrencyValue,
    key: 'npv',
    label: 'NPV',
    sortable: true,
    tdClass: 'text-nowrap',
  },
  {
    key: 'has_8760',
    label: 'Has 8760',
    sortable: true,
  },
];
