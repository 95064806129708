<template>
  <div class="header-container mb-3">
    <b-navbar class="px-3 py-0">
      <b-navbar-brand data-test-id="header-home-link" to="/">
        <img
          v-if="this.isDarkMode"
          alt="Artemis Advisor"
          class="d-inline-block align-middle"
          height="32"
          src="@/assets/artemis-advisor-logo-dark-mode.png"
        />
        <img
          v-else
          alt="Artemis Advisor"
          class="d-inline-block align-middle"
          height="32"
          src="@/assets/artemis-advisor-logo-light-mode.png"
        />
      </b-navbar-brand>
      <b-navbar-nav class="align-items-baseline ml-auto">
        <b-nav-item
          v-if="!isDeveloper"
          :active="isHomepage"
          class="font-weight-bold"
          to="/rfps"
        >
          RFPs
        </b-nav-item>
        <b-nav-item
          v-if="isAdmin"
          class="font-weight-bold ml-4"
          to="/administration"
        >
          Administration
        </b-nav-item>
        <div class="ml-4">
          <b-nav-item-dropdown right class="border-muted p-0">
            <template v-slot:button-content>
              <span class="font-weight-bold">{{ email }}</span>
            </template>
            <b-dropdown-item to="/settings">
              Settings
            </b-dropdown-item>
            <b-dropdown-item variant="warning" @click="handleLogOut">
              Log Out
            </b-dropdown-item>
          </b-nav-item-dropdown>
        </div>
      </b-navbar-nav>
    </b-navbar>
  </div>
</template>

<script>
import { get } from 'vuex-pathify';
import { logout } from '@/service/AuthService';

export default {
  name: 'Header',
  data: () => {
    return {
      isHomepage: false,
    };
  },
  computed: {
    ...get('user', ['email', 'isAdmin', 'isDeveloper', 'isDarkMode']),
  },
  watch: {
    '$route.path': {
      handler(newPath) {
        this.isHomepage = newPath === '/';
      },
      immediate: true,
    },
  },
  methods: {
    handleLogOut() {
      logout();
    },
  },
};
</script>

<style lang="scss" scoped>
.header-container {
  background: var(--dark-gradient);
}
::v-deep .nav-item {
  &.show {
    background-color: var(--secondary);
  }
}
::v-deep .nav-link {
  color: var(--white) !important;
  font-size: 0.9375rem;
  padding-bottom: 0.9375rem;
  padding-top: 0.9375rem;
  position: relative;
  &:hover {
    opacity: 0.75;
  }
  &.active,
  &.router-link-active {
    color: var(--primary) !important;
    &::after {
      background: var(--primary);
      border-radius: 1rem;
      content: '';
      position: absolute;
      bottom: 6px;
      height: 4px;
      width: 1rem;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
::v-deep .dropdown-item {
  border-radius: 4px;
  font-weight: bold;
  padding: 0.75rem 1rem;
  text-align: center;
  transition: opacity 150ms;
  &:hover {
    opacity: 0.75;
  }
  &:not(.text-warning) {
    background-color: var(--secondary);
    color: var(--white);
  }
  &.text-warning {
    background-color: var(--warning);
    color: black !important;
  }
}
::v-deep .dropdown-menu {
  background: var(--dark);
  border-radius: 8px;
  box-sizing: border-box;
  min-width: 11rem;
  padding: 4px;
  right: 4px;
  li + li {
    margin-top: 4px;
  }
}
::v-deep .dropdown-toggle::after {
  vertical-align: 0.15em;
}
</style>
