import Vue from 'vue';
import Vuex from 'vuex';
import pathify from 'vuex-pathify';
import uiRfpView from './modules/uiRfpView';
import user from './modules/user';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: { uiRfpView, user },
  plugins: [pathify.plugin],
  strict: process.env.NODE_ENV !== 'production',
});
