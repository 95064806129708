<template>
  <div :data-environment="environment">
    <div id="app" class="app-container d-flex flex-column">
      <div v-if="loading" class="mt-4" style="text-align: center;">
        Logging In
        <b-spinner class="ml-2" small />
      </div>
      <div v-else class="flex-grow-1 pb-4">
        <AuthWrapper>
          <Header />
          <router-view />
        </AuthWrapper>
      </div>
      <Footer v-if="false" />
    </div>
  </div>
</template>

<script>
import { get } from 'vuex-pathify';
import ApiService from '@/service/ApiService';
import {
  extendSession,
  getRefreshToken,
  getSavedPath,
  getTokens,
  login,
} from '@/service/AuthService';
import AuthWrapper from '@/components/AuthWrapper';
import Footer from '@/components/Footer';
import Header from '@/components/Header';

export default {
  components: {
    AuthWrapper,
    Footer,
    Header,
  },
  data() {
    return {
      environment: process.env.NODE_ENV,
      loading: false,
      submittingTheme: false,
    };
  },
  computed: {
    ...get('user', ['attributes', 'isDarkMode']),
  },
  watch: {
    isDarkMode() {
      this.setThemeClass();
    },
  },
  created() {
    this.authenticateUser();
    this.setThemeClass();
    window.addEventListener('keypress', this.handleKeyPress);
  },
  beforeDestroy() {
    window.removeEventListener('keypress', this.handleKeyPress);
  },
  methods: {
    async authenticateUser() {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');
      const hasRefreshToken = Boolean(getRefreshToken());
      if (code) {
        this.initiateSession(code);
      } else if (hasRefreshToken) {
        this.attemptSessionRefresh();
      } else {
        login();
      }
    },
    async attemptSessionRefresh() {
      if (process.env.NODE_ENV !== 'production') {
        this.loading = true;
      }
      try {
        await extendSession();
        const userResponse = await ApiService.userGetCurrent();
        this.$store.dispatch('user/setAttributes', userResponse);
      } catch (error) {
        this.$log.error('Unable to refresh session', error);
        login();
      } finally {
        this.loading = false;
      }
    },
    async initiateSession(code) {
      if (process.env.NODE_ENV !== 'production') {
        this.loading = true;
      }
      try {
        const savedPath = getSavedPath() || '/';
        const newPath = savedPath === '/login' ? '/' : savedPath;
        await getTokens(code);
        const userResponse = await ApiService.userGetCurrent();
        this.$store.dispatch('user/setAttributes', userResponse);
        this.$router.push(newPath).catch(() => {});
      } catch (error) {
        this.$log.error('Unable to log in', error);
        if (window.confirm('Artemis Advisor failed to log in. Try again?')) {
          login();
        }
      } finally {
        this.loading = false;
      }
    },
    handleKeyPress(event) {
      const isShortcutKey = ['t', 'T'].includes(event.key);
      const isTyping = ['INPUT', 'SELECT'].includes(
        document.activeElement.tagName
      );
      if (!isShortcutKey || isTyping) return;
      this.toggleTheme();
    },
    setThemeClass() {
      const classesToReset = ['theme-darkmode', 'theme-lightmode'];
      const className = this.isDarkMode ? 'theme-darkmode' : 'theme-lightmode';
      const rootElement = document.documentElement;
      rootElement.classList.remove(...classesToReset);
      rootElement.classList.add(className);
    },
    async toggleTheme() {
      if (this.submittingTheme) return;
      this.submittingTheme = true;
      try {
        const themeName = this.isDarkMode ? 'Light' : 'Dark';
        this.$store.dispatch('user/setAttributes', {
          ...this.attributes,
          theme_preference: themeName.toUpperCase(),
        });
        await ApiService.userEditThemePreference(themeName);
        setTimeout(() => {
          this.$bvToast.toast(themeName, {
            title: 'Updated Theme Setting',
          });
        }, 100);
      } catch (error) {
        this.error = error;
        this.$log.error('Unable to edit theme preference', error);
      } finally {
        this.submittingTheme = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.app-container {
  min-height: 100vh;
}
</style>
